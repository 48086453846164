import Image from 'next/image';
import React, { ReactNode } from 'react';
import { StyledDiv } from './feature.styles';
import { IoIosArrowForward } from '@react-icons/all-files/io/IoIosArrowForward';
import { CustomLink } from '../link/link.comp';
import { FaCheck } from '@react-icons/all-files/fa/FaCheck';
import { CSSVars } from '../../styles/styles.config';
import { CommonNinjaWidgetRenderer } from '../CommonNinjaWidgetRenderer/commonNinjaWidgetRenderer.comp';

interface IFeatureProps {
	position: 'rtl' | 'ltr' | 'ttb';
	title?: string;
	title2?: string;
	subtitle?: string;
	text: string;
	text2?: string;
	img?: string;
	imgCaption?: string;
	alt?: string;
	linkText?: string;
	linkPath?: string;
	className?: string;
	titleSize?: number;
	isHtml?: boolean;
	mode?: 'dark' | 'light';
	icon?: ReactNode;
	checkedItems?: string[];
	openInNewWindow?: boolean;
	pluginId?: string;
}

export const Feature = ({
	isHtml = false,
	imgCaption,
	subtitle,
	mode = 'light',
	position = 'ltr',
	img,
	text,
	title,
	alt = 'feature',
	linkText,
	linkPath = '/',
	className = '',
	text2,
	title2,
	titleSize,
	icon,
	checkedItems = [],
	openInNewWindow = false,
	pluginId,
}: IFeatureProps) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const style: any = {};
	const moreThanOneTitleOrText = title2 || text2;

	if (titleSize) {
		style.fontSize = titleSize;
	}

	return (
		<StyledDiv
			className={`${position} ${className} ${mode} ${
				!img ? 'full-width' : ''
			} feature`}
		>
			<div className="content">
				{icon && <div className="icon-wrapper">{icon}</div>}
				<div
					className={`details-wrapper ${
						!img || position === 'ttb' ? 'wide' : ''
					} ${moreThanOneTitleOrText ? 'mini' : ''}`}
				>
					<div className="wrapper">
						{title && (
							<h2
								style={style}
								className={moreThanOneTitleOrText ? 'mini' : ''}
							>
								{title}
							</h2>
						)}
						{subtitle && <h3>{subtitle}</h3>}
						{isHtml ? (
							<div
								className="html"
								dangerouslySetInnerHTML={{ __html: text }}
							></div>
						) : (
							<p>{text}</p>
						)}
					</div>
					<div className="wrapper second">
						{title2 && (
							<h2
								style={style}
								className={moreThanOneTitleOrText ? 'mini' : ''}
							>
								{title2}
							</h2>
						)}
						{text2 && <p>{text2}</p>}
					</div>
					{checkedItems.length > 0 && (
						<div className="checked">
							{checkedItems.map((text, idx) => (
								<p key={`${text}-${idx}`}>
									<FaCheck
										className="icon"
										size={14}
										color={CSSVars.blue}
									/>
									{text}
								</p>
							))}
						</div>
					)}
				</div>
				{linkText && (
					<CustomLink
						target={openInNewWindow ? '_blank' : '_self'}
						href={linkPath}
					>
						{linkText}
						<IoIosArrowForward fontSize={18} className="icon" />
					</CustomLink>
				)}
			</div>
			{pluginId && (
				<div className="widget-wrapper">
					<CommonNinjaWidgetRenderer key={text + ' pluginId'} widgetId={pluginId} />
				</div>
			)}
			{img && (
				<figure className="feature-img-wrapper">
					<Image src={img} alt={alt} width={685} height={400} />
					{imgCaption && (
						<figcaption className="caption">{imgCaption}</figcaption>
					)}
				</figure>
			)}
		</StyledDiv>
	);
};
