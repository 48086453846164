import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledWidgetHero = styled.section`
	text-align: center;
	background: url(/assets/hero-bg.webp);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: center 0%;
	padding: 10px 0px 50px;

	@media screen and (min-width: ${CSSVars.breakpointLG}) {
		padding: 20px 0px 50px;
	}

	.hero-wrapper {
		position: relative;

		h1 {
			margin: 0 auto;
			max-width: 1100px;
			margin-bottom: 20px;
			/* width: fit-content; */

			font-size: ${CSSVars.fontSize2XL};
			text-align: left;

			padding: 0 20px;

			&.coming-soon {
				position: relative;
				@media screen and (min-width: ${CSSVars.breakpointSM}) {
					width: fit-content;
				}

				&::after {
					content: 'Coming Soon';
					position: absolute;
					right: -10px;
					top: -13px;
					font-size: 12px;
					padding: 5px 15px;
					border-radius: 5px;
					background-color: ${CSSVars.blue500};
					box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
					transform: rotate(10deg);
				}
			}

			@media screen and (min-width: ${CSSVars.breakpointSM}) {
				text-align: center;
				font-size: ${CSSVars.fontSize3XL};
			}

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				text-align: center;
				font-size: ${CSSVars.fontSize4XL};
			}
		}

		h2 {
			font-size: ${CSSVars.fontSizeLG};
			max-width: 780px;
			font-weight: 300;

			margin: 0 auto;
			text-align: left;
			padding: 0 20px;

			@media screen and (min-width: ${CSSVars.breakpointSM}) {
				text-align: center;
			}

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				max-width: 850px;
			}
		}

		.button-wrapper {
			margin: 30px auto 30px 0;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding: 0 20px;

			@media screen and (min-width: ${CSSVars.breakpointSM}) {
				align-items: center;
				margin: 30px auto 80px;
			}

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				margin: 50px auto 80px;
				font-size: ${CSSVars.fontSizeXL};
			}
		}
	}

	.hero-img-wrapper {
		position: relative;
		margin: 0 auto;
		
		.platform-img-wrapper {
			transform: translate(0,-50%) scale(1.8);
			left: 22%;
			position: absolute;
			opacity: 0.4;

			img {
				min-height: 60px;
				max-width: 600px;
				max-height: 600px;
				object-fit: contain;
				position: relative !important;
			}
		}
	}
`;
