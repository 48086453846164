import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledDiv = styled.div`
	max-width: 1240px;
	margin: 30px auto 70px;
	padding: 0 20px;
	
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px;
	
	@media screen and (max-width: ${CSSVars.breakpointMD}) {
		margin: -30px auto 70px;
		grid-template-columns: repeat(1, 1fr);
	}

	.quote {
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: center;

		p {
			position: relative;
	
			&::before {
				content: "“";
				font-size: 20px;
				margin-right: 3px;
				line-height: 1;
			}

			&::after {
				content: "“";
				font-size: 20px;
				margin-left: 3px;
				line-height: 1;
			}
		}
	}
`;
