import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledDiv = styled.div`
	color: ${CSSVars.gray900};
	max-width: 1420px;
	margin: 0 auto;

	display: flex;
	flex-wrap: wrap;
	gap: 50px;

	flex-direction: column;

	padding: 50px 20px;

	@media screen and (min-width: ${CSSVars.breakpointLG}) {
		padding: 100px 40px;
	}

	.card {
		&:nth-child(even) {
			flex-direction: row-reverse;
		}
	}

	.benefits {
		display: flex;
		flex-wrap: wrap;
		gap: 30px;
		margin: 100px 0;

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			gap: 50px;
		}

		.card {
			flex: 300px;

			img {
				width: 30px;
				height: 30px;
				object-fit: contain;

				@media screen and (min-width: ${CSSVars.breakpointMD}) {
					width: 30px;
					height: 30px;
				}
			}

			h3 {
				font-weight: 600;
				font-size: ${CSSVars.fontSizeLG};
				margin: 20px 0 10px;

				@media screen and (min-width: ${CSSVars.breakpointMD}) {
					margin: 30px 0 10px;
					font-size: ${CSSVars.fontSizeXL};
				}
			}

			p {
				font-size: ${CSSVars.fontSizeMD};
				font-weight: 300;

				@media screen and (min-width: ${CSSVars.breakpointMD}) {
					font-size: ${CSSVars.fontSizeLG};
				}
			}
		}
	}
`;
