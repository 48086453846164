'use client';

import React from "react";
import { IPage, IPlugin } from "../../types";
import { StyledDiv } from "./widgetFeatures.styles";
import { Feature } from "../feature/feature.comp";
import Image from "next/image";

export const WidgetFeatures = ({
  widget,
  page,
}: {
  widget: IPlugin;
  page?: IPage;
}) => {
  // Features
  const sectionFeaturesApp = widget.features.filter(
    (feature) => feature.type === "section" || !feature.type
  );
  const sectionFeaturesPage = page?.rewrites.features
    .map((f, idx) => ({
      ...f,
      type: widget.features[idx]?.type,
      imgUrl: widget.features[idx]?.imgUrl || f.imgUrl,
    }))
    .filter((feature) => feature.type === "section" || !feature.type);
  const finalFeaturesSections =
    (!page ? sectionFeaturesApp : sectionFeaturesPage) || [];
  const middle = Math.ceil(finalFeaturesSections.length / 2);
  const first = finalFeaturesSections.slice(0, middle);
  const second = finalFeaturesSections.slice(middle);

  // Boxes
  const boxFeaturesApp = widget.features.filter(
    (feature) => feature.type === "box"
  );
  const boxFeaturesPage = page?.rewrites.features
    .map((f, idx) => ({
      ...f,
      type: widget.features[idx]?.type,
      imgUrl: widget.features[idx]?.imgUrl || f.imgUrl, // Use images from original features
    }))
    .filter((feature) => feature.type === "box");
  const finalFeaturesBox = (!page ? boxFeaturesApp : boxFeaturesPage) || [];

  return (
    <StyledDiv>
      {first.map((feature, idx) => (
        <Feature
          key={`${feature.title}-app`}
          title={feature.title}
          text={feature.text}
          position={idx % 2 === 0 ? "ltr" : "rtl"}
          alt={`${widget?.name} - ${feature.title}`}
          img={feature.imgUrl}
        />
      ))}
      {finalFeaturesBox.length > 0 && (
        <div className="benefits">
          {finalFeaturesBox.map((feature) => (
            <div key={feature.title} className="card">
              <Image
                src={feature.imgUrl}
                alt={`${widget?.name} - ${feature.title}`}
                width={30}
                height={30}
              />
              <h3>{feature.title}</h3>
              <p>{feature.text}</p>
            </div>
          ))}
        </div>
      )}
      {second.map((feature, idx) => (
        <Feature
          key={`${feature.title}-app`}
          title={feature.title}
          text={feature.text}
          position={idx % 2 === 0 ? "ltr" : "rtl"}
          alt={`${widget?.name} - ${feature.title}`}
          img={feature.imgUrl}
        />
      ))}
    </StyledDiv>
  );
};
