import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledDiv = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 30px;
	flex-direction: column-reverse;

	@media screen and (min-width: ${CSSVars.breakpointLG}) {
		flex-direction: row;
	}

	@media screen and (min-width: ${CSSVars.breakpointXL}) {
		gap: 50px;
	}

	+ .commonninja_component {
		margin-top: -100px;
	}

	&.dark {
		.content {
			color: ${CSSVars.white};
		}
	}

	&.full-width {
		.content .details-wrapper .wrapper p {
			max-width: 100%;
			@media screen and (min-width: ${CSSVars.breakpointLG}) {
				max-width: 100%;
			}
		}

		&.rtl,
		&.ltr,
		&.ttb {
			display: block;
		}
	}

	&.rtl {
		flex-direction: column-reverse;

		@media screen and (min-width: ${CSSVars.breakpointLG}) {
			flex-direction: row-reverse;
		}
	}

	&.ttb {
		flex-direction: column;

		.content .details-wrapper .wrapper p {
			max-width: 100% !important;
		}
	}

	.widget-wrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: center;
	}

	.feature-img-wrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;

		img {
			max-width: 100%;
			height: 100%;
		}

		.caption {
			font-size: ${CSSVars.fontSizeSM};
			text-align: center;
		}
	}

	.content {
		color: ${CSSVars.black};

		.icon-wrapper {
			width: fit-content;
			background-color: ${CSSVars.gray100};
			border: 1px solid ${CSSVars.gray200};
			padding: 10px;
			border-radius: 5px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 20px;
		}

		.details-wrapper {
			display: flex;
			flex-direction: column;
			max-width: 650px;

			&.wide {
				max-width: 100%;
			}

			&.mini {
				gap: 30px;

				@media screen and (min-width: ${CSSVars.breakpointLG}) {
					gap: 50px;
				}
			}

			.checked {
				display: flex;
				flex-direction: column;
				gap: 10px;
				margin-top: 10px;

				p {
					display: flex;
					align-items: center;
					gap: 12px;

					font-weight: 300;
					font-size: ${CSSVars.fontSizeMD};
					max-width: 650px;

					@media screen and (min-width: ${CSSVars.breakpointLG}) {
						font-size: ${CSSVars.fontSizeLG};
					}

					.icon {
						min-width: 14px;
					}
				}
			}

			.wrapper {
				.html {
					a {
						display: initial;
					}

					ul {
						/* list-style: unset; */
						padding-left: 20px;
						margin: 15px 0;

						li {
							margin: 5px 0;
							position: relative;

							&::before {
								content: '.';
								color: ${CSSVars.blue};
								font-size: 50px;
								position: absolute;
								left: -20px;
								top: -38px;
								color: ${CSSVars.blue};
							}
						}
					}

					ol {
						list-style: decimal;
						padding-left: 20px;
						margin: 15px 0;

						li {
							&::before {
								display: none;
							}
							margin: 5px 0;

							span {
								padding: 0 0 10px 10px;
								display: block;
							}

							&::marker {
								font-weight: 500;
							}
						}
					}
				}

				h2 {
					font-size: ${CSSVars.fontSizeXL};
					margin-bottom: 10px;
					font-weight: 500;

					&.mini {
						font-size: ${CSSVars.fontSizeXL};
						margin-bottom: 20px;
					}
				}

				h3 {
					font-size: ${CSSVars.fontSizeLG};
					margin-bottom: 20px;

					@media screen and (min-width: ${CSSVars.breakpointXL}) {
						font-size: ${CSSVars.fontSizeXL};
					}
				}

				p {
					font-weight: 300;
					font-size: ${CSSVars.fontSizeMD};
					max-width: 600px;
					opacity: 0.6;

					@media screen and (min-width: ${CSSVars.breakpointLG}) {
						max-width: 700px;
					}
				}
			}
		}

		a {
			margin-top: 20px;
			color: ${CSSVars.blue};
			display: flex;
			align-items: center;
			gap: 10px;
			transition: 0.2s;
			font-size: ${CSSVars.fontSizeMD};
			background-color: none;
			background: none;
			padding: 0;

			&:hover {
				color: ${CSSVars.blue300};
			}

			.icon {
				padding-top: 2.5px;
			}
		}
	}
`;
